*{
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Ubuntu", 'Arial', "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
  "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  /* font-size: 16px !important; */
}

ul{
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important;
}

a {
  color: #000;
  text-decoration: none;
}

button {
  text-transform: initial !important;
}
